<template>
	<LoganLoading>
		<div class="mt-5">Redirecting to Keycloak...</div>
	</LoganLoading>
</template>
<script>
import LoganLoading from "./LoganLoading.vue";
export default {
	components: {
		LoganLoading,
	},
	data() {
		return {};
	},
	async created() {},
};
</script>
