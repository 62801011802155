var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LoganLoading", [
    _c("div", { staticClass: "mt-5" }, [_vm._v("Redirecting to Keycloak...")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }